import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import config from 'common/config'
import { graphql, useStaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import Urls from 'common/urls'

type Props = {
  description?: string
  keywords?: readonly string[]
  title: string
  type?: string
  url?: string
  image?: string
  structuredData?: readonly StructuredData[]
}

type QueryResult = {
  defaultImage: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const query = graphql`
  query {
    defaultImage: file(relativePath: { eq: "social.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const SEO: FC<Props> = ({
  title,
  description = 'Test and improve your fertility level, all from home.',
  keywords = [],
  structuredData = [],
  type = 'website',
  image,
  url,
  children,
}) => {
  const websiteSD: StructuredData = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: config.siteUrl,
    name: title,
    alternateName: title,
  }
  const { defaultImage } = useStaticQuery<QueryResult>(query)
  const defaultImageURL = `${config.siteUrl}${defaultImage.childImageSharp.fluid.src}`
  const onCliniciansPage =
    typeof window !== 'undefined' &&
    window.location.pathname === Urls.clinicians
  const intercomSettings: any = {
    app_id: config.intercomWorkspaceID,
    hide_default_launcher: !onCliniciansPage,
    // This padding only applies to desktop, to make sure the icon doesn't cover the
    // sticky footer in mobile we apply a global CSS rule in `src/styles/global.ts`
    vertical_padding: 90,
  }
  return (
    <Helmet
      title="Fellow"
      titleTemplate={`%s | ${title}`}
      htmlAttributes={{
        lang: 'en-US',
      }}
      defer={false}
    >
      {/* Load async scripts */}
      <script
        type="text/javascript"
        async
        src={`https://widget.intercom.io/widget/${config.intercomWorkspaceID}`}
      ></script>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${config.gaID}`}
      ></script>

      {/* Initialize datalayer for Google scripts */}
      <script>{`window.dataLayer = window.dataLayer || [{"gaPropertyId": "${config.gaID}"}];`}</script>

      {/* Anti-flicker snippet (recommended)
          If you use Google Optimize, it can sometimes cause flickering of the page content on initial load.
          To help prevent this, you can add this snippet to your head tag. Unfortunately this snippet was
          leading to long perceived load times due to the opacity of the page being set to 0 until all the SEO
          content has loaded.
      */}
      {/* <style>{`.async-hide { opacity: 0 !important};`}</style>
      <script>
        {`(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
        h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
        (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
        })(window,document.documentElement,'async-hide','dataLayer',4000,
        {'GTM-P76FMSJ':true});`}
      </script> */}
      {/* End Anti-flicker snippet */}

      {/*  Global Site Tag (gtag.js) - Google Analytics + Optimize */}
      {/*  + Netlify Branch Deploys config (for A/B testing) */}
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        window.ab_branch = '${config.branch}';
        var dimensionValue = window.ab_branch;
        gtag(
          'config',
          '${config.gaID}',
          {
            'optimize_id': 'GTM-P76FMSJ',
            'linker': {'domains': ['meetfellow.com']},
            'dimension1': dimensionValue
          }
        );`}
      </script>
      {/*  End Global Site Tag (gtag.js) - Google Analytics + Optimize */}

      {/* Google Tag Manager */}
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5S3GN6T');`}
      </script>
      {/* End Google Tag Manager */}

      {/* Intercom */}
      <script>
        {`window.intercomSettings = ${JSON.stringify(intercomSettings)}`}
      </script>
      {/*
       * This snippet has been modified to not include the lines of code that attempt to re-add the async
       * Intercom script tag on page loads. We have hard-coded that tag in the async scripts above
       */}
      <script>
        {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');
        ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];
        i.c=function(args){i.q.push(args);};w.Intercom=i;}})();`}
      </script>
      {/* End Intercom */}
      {/* Postalytics */}
      <script>
        {`var a;var rc = new RegExp('_bn_d=([^;]+)');var rq = new RegExp('_bn_d=([^&#]*)', 'i');
        var aq = rq.exec(window.location.href);if (aq != null) a=aq;else var ac = rc.exec(document.cookie);
        if (ac != null) a=ac;if (a != null) {var _bn_d = a[1];(function() {var pl = document.createElement('script');
        pl.type = 'text/javascript'; pl.async = true;pl.src = ('https:' == document.location.protocol ? 'https://app' : 'http://app') + '.postaladmin.com/plDataEmbed.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(pl, s);})();}`}
      </script>
      {/* End Postalytics */}

      <script type="application/ld+json">
        {JSON.stringify([...structuredData, websiteSD])}
      </script>

      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url || config.siteUrl} />
      <meta property="og:image" content={image || defaultImageURL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@fellow" />

      {children}
    </Helmet>
  )
}

export default SEO
