import React, { useMemo, FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Accordion from 'components/accordion'
import { AccordionWrap, SectionHeadline, Section, SupportCTA } from './styled'

import Urls from 'common/urls'

type GroupResult<T> = {
  group: Array<{
    edges: readonly T[]
    fieldValue: string
  }>
}

type QueryResult = {
  faq: GroupResult<FAQItem>
}

type FAQListItem = {
  key: string
  label: string
  items: Array<{
    node: FAQItem
  }>
}

export enum FAQSectionCategory {
  ABOUT = 'ABOUT',
  ORDERING = 'ORDERING',
  SAMPLE_COLLECTION = 'SAMPLE_COLLECTION',
  VASECTOMY = 'VASECTOMY',
  SEMEN_ANALYSIS = 'SEMEN_ANALYSIS',
  CRYO = 'CRYO',
  SPERM_COUNT_TEST = 'SPERM_COUNT_TEST',
  DOCTORS = 'DOCTORS',
}

type FAQItemsList = FAQListItem[]

type SectionFilters = FAQSectionCategory[]

const sectionOrder = [
  {
    key: FAQSectionCategory.ABOUT,
    label: 'About<span translate="no"> Fellow</span>',
    items: [],
  },
  {
    key: FAQSectionCategory.ORDERING,
    label: 'Ordering your kit',
    items: [],
  },
  {
    key: FAQSectionCategory.SAMPLE_COLLECTION,
    label: 'Producing your sample',
    items: [],
  },
  {
    key: FAQSectionCategory.VASECTOMY,
    label: 'About the <span translate="no">Fellow</span> Vasectomy Test',
    items: [],
  },
  {
    key: FAQSectionCategory.SEMEN_ANALYSIS,
    label: 'About Semen Analysis with <span translate="no">Fellow</span> (for fertility testing)',
    items: [],
  },
  {
    key: FAQSectionCategory.CRYO,
    label: 'About cryopreservation with <span translate="no">Fellow</span>',
    items: [],
  },
  {
    key: FAQSectionCategory.SPERM_COUNT_TEST,
    label: "Sperm Count Test questions",
    items: [],
  },
  {
    key: FAQSectionCategory.DOCTORS,
    label: "",
    items: [],
  },
]

const orderAndFilterSections = (
  faq: GroupResult<FAQItem>,
  sectionFilters: SectionFilters,
): FAQItemsList => {
  // @ts-ignore
  return sectionFilters.flatMap(sectionFilter => {
    const sectionGroup = faq.group.find(
      group => group.fieldValue === sectionFilter
    )
    const section = sectionOrder.find(
      order => order.key === sectionFilter
    )
    if (!sectionGroup || !section) {
      return []
    }

    return {
      ...section,
      items: sectionGroup.edges,
    }
  })
}

const mapNodeToAccordionItem = (node: FAQItem) => ({
  key: node.id,
  title: node.title,
  content: <div>{node.body}</div>,
})

const query = graphql`
  query {
    faq: allFaqJson(
      filter: { section: { ne: null } }
      sort: { fields: order }
    ) {
      group(field: section) {
        edges {
          node {
            title
            id
            section
            order
            body
          }
        }
        fieldValue
      }
    }
  }
`

type Props = {
  sectionFilters?: SectionFilters
  supportMessage?: string
  linkToAllFaqs?: boolean
  alternateContactEmail?: string
}

const FAQAccordion: FC<Props> = ({
  sectionFilters = Object.keys(FAQSectionCategory) as SectionFilters,
  supportMessage,
  linkToAllFaqs,
  alternateContactEmail,
}) => {
  const { faq } = useStaticQuery<QueryResult>(query)
  const faqItems = useMemo<FAQItemsList>(() => {
    return orderAndFilterSections(faq, sectionFilters)
  }, [faq, sectionFilters])

  const supportMessageText = supportMessage
    ? supportMessage
    : 'Have a different question?'

  const contactEmail = alternateContactEmail ? alternateContactEmail : 'support@meetfellow.com'

  return (
    <>
      <AccordionWrap>
        {faqItems.map(section => (
          <Section key={section.key}>
            <SectionHeadline dangerouslySetInnerHTML={{ __html: section.label}} />
            <Accordion
              items={section.items.map(node =>
                mapNodeToAccordionItem(node.node)
              )}
            />
          </Section>
        ))}
      </AccordionWrap>
      <SupportCTA>
        {`${supportMessageText} `}
        {linkToAllFaqs ? (
          <span>
            <a href={Urls.faq}>See all our FAQs</a> or&nbsp;
          </span>
        ) : null}
        <a href={`mailto:${contactEmail}`}>Contact us</a> at {contactEmail}
      </SupportCTA>
    </>
  )
}

export default FAQAccordion
