import React, { FC, useState, ReactNode, Fragment } from 'react'

import AccordionControl from './accordion-control'
import { Wrap, Button, Content, AccordionContent } from './styled'

type Props = {
  items: ReadonlyArray<{
    title: string | ReactNode
    content: string | ReactNode
    key: string
  }>
}

const Accordion: FC<Props> = ({ items = [] }) => {
  const [opened, setOpened] = useState(new Set())

  const toggleSection = (id: string) => {
    if (opened.has(id)) {
      opened.delete(id)
    } else {
      opened.add(id)
    }

    setOpened(new Set(opened))
  }

  return (
    <Wrap>
      {items.map(item => (
        <Fragment key={item.key}>
          <Button
            onClick={() => toggleSection(item.key)}
            aria-expanded={opened.has(item.key)}
          >
            <AccordionControl isOpen={opened.has(item.key)} text={item.title} />
          </Button>
          <Content isOpen={opened.has(item.key)} role="region">
            <AccordionContent>{item.content}</AccordionContent>
          </Content>
        </Fragment>
      ))}
    </Wrap>
  )
}

export default Accordion
