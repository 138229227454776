import React, { FC, ReactNode } from 'react'

import theme from 'styles/theme'
import Minus from 'components/icons/minus'
import Plus from 'components/icons/plus'
import { Title } from './styled'

type Props = {
  text: string | ReactNode
  isOpen?: boolean
}

const AccordionControl: FC<Props> = ({ isOpen = false, text }) => {
  const IconComponent = isOpen ? Minus : Plus

  return (
    <Title>
      {text}
      <IconComponent
        width={30}
        height={30}
        viewBox="0 0 30 30"
        color={theme.color.accent.primary}
      />
    </Title>
  )
}

export default AccordionControl
