import styled from 'styled-components'
import theme from 'styles/theme'
import { font, paragraph, heading } from 'styles/typography'

export const Wrap = styled.article`
  display: flex;
  flex-direction: column;
  border-bottom: 0.1rem ${theme.color.border.blue} solid;
`

export const Button = styled.button`
  text-align: left;
  padding: 0;
  font: ${font.headings};
  background: no-repeat;
  border: none;
`

export const Content = styled.div<{
  isOpen: boolean
}>`
  display: ${props => (props.isOpen ? 'block' : 'none')};
`

export const Title = styled.p`
  ${heading.xs};
  border-top: 0.1rem ${theme.color.border.blue} solid;
  margin: 0;
  padding: 3.5rem 0 3.2rem;
  display: flex;
  justify-content: space-between;

  svg {
    margin-left: 2rem;
    min-width: 30px;
  }
`

export const AccordionContent = styled.div`
  padding-bottom: 3rem;
  ${paragraph.small};
`
