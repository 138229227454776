import React, { FC, SVGProps } from 'react'
import Svg from '../svg'

const Plus: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props}>
    <g
      stroke="currentColor"
      strokeWidth="3"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M5 15h20" />
      <path d="M15 5v20" />
    </g>
  </Svg>
)

export default Plus
