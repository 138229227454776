import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Container from 'components/container'
import FAQAccordion, { FAQSectionCategory } from 'components/faq'
import { Section, Title } from './styled'

const FAQ = () => (
  <Layout>
    <SEO title="FAQ" />
    <Section>
      <Container>
        <Title>Frequently Asked Questions</Title>
        <FAQAccordion
          sectionFilters={[
            FAQSectionCategory.ABOUT,
            FAQSectionCategory.ORDERING,
            FAQSectionCategory.SAMPLE_COLLECTION,
            FAQSectionCategory.VASECTOMY,
            FAQSectionCategory.SEMEN_ANALYSIS,
            FAQSectionCategory.CRYO,
          ]}
        />
      </Container>
    </Section>
  </Layout>
)

export default FAQ
