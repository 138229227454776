import styled from 'styled-components'

export const AccordionWrap = styled.div`
  margin-top: 2rem;
`

export const SectionHeadline = styled.h2 `
  margin-bottom: 2.3rem;
`

export const Section = styled.section `
  margin-bottom: 8rem;
`

export const SupportCTA = styled.p`
  text-align: center;
`